<template>
  <div class="deviceInfo">
    <a-modal v-model="visible" title="新增报修" :width="851" centered @ok="confirmModal" okText="提交">
        <div class="modal_info">
            <div class="modal_info_content">
                <a-form-model :model="pageData" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item label="设备编码" prop="deviceCode">
                                <a-input v-model="pageData.deviceCode" placeholder="请输入设备编码" @blur="getDevice" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="设备名称" prop="deviceName">
                                <a-input v-model="pageData.deviceName" placeholder="输入设备编码后自动匹配" disabled />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item label="报修人" prop="repairPerson">
                                <a-input v-model="pageData.repairPerson" placeholder="根据当前账号自动代入" disabled  />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="联系方式">
                                <a-input v-model="pageData.phone" placeholder="根据当前账号自动代入" disabled  />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item label="设备位置" prop="deviceAddress">
                                <a-input v-model="pageData.deviceAddress" placeholder="输入设备编码后自动匹配" disabled />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="24">
                            <a-form-model-item label="报修内容" prop="repairContent" :label-col="{ span: 3}" :wrapper-col="{ span: 19 }">
                                <a-textarea
                                    v-model="pageData.repairContent"
                                    :auto-size="{ minRows: 3, maxRows: 5 }"
                                />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="24">
                            <a-form-model-item label="附件照片" prop="repairTime" :label-col="{ span: 3}" :wrapper-col="{ span: 19 }">
                                <UploadImage @setImage="setImage" :maxNum="3" :imgList="pageData.picture"/>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
        </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import UploadImage from "@/component/uploadImage/uploadImage";
import {
    addDeviceReport,
    getDeviceByCode,
    getCurrentInfo
} from '@/api/deviceManage'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: "deviceInfo",
  data() {
    return {
      visible :false,
      pageData : {
          deviceCode : '',
          deviceName : '',
          repairPerson : '',
          phone : '',
          deviceAddress : '',
          repairTime : '',
          repairContent : '',
          picture : [],
      },
      labelCol: { span: 6},
      wrapperCol: { span: 14 },
      previewVisible: false,
      previewImage: '',
      fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
        {
          uid: '-2',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
        {
          uid: '-3',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
      ],
    };
  },
    components: {
        UploadImage
    },
    props: {
        deviceCode : {
            type : String,
            default : ''
        }
    },
  methods: {
    moment,
    showModal(){
        if(this.deviceCode == ''){
            this.pageData = {
                deviceCode : '',
                deviceName : '',
                repairPerson : '',
                phone : '',
                deviceAddress : '',
                repairTime : '',
                repairContent : '',
                picture : [],
            }
        }else{
            this.pageData = {
                deviceCode : this.deviceCode,
                deviceName : '',
                repairPerson : '',
                phone : '',
                deviceAddress : '',
                repairTime : '',
                repairContent : '',
                picture : [],
            }
            this.getDevice()
        }
        this.getAdmin()
        this.visible = true
    },
    setImage(val){
        this.pageData.picture=val
    },
    getAdmin(){
        let data = {}
        getCurrentInfo(data).then(res=>{
            this.pageData.repairPerson = res.data.data.name
            this.pageData.phone = res.data.data.phone
        })
    },
    getDevice(){
        let data = {
            deviceCode : this.pageData.deviceCode
        }
        getDeviceByCode(data).then(res=>{
            if(res.data.data){
                this.pageData.deviceName = res.data.data.name
                this.pageData.deviceAddress = res.data.data.address
            }else{
                this.pageData.deviceName = ''
                this.pageData.deviceAddress = ''
            }
        })
    },
    confirmModal(){
        let data = {
            deviceCode : this.pageData.deviceCode,
            reportRemark : this.pageData.repairContent,
            reportImages : this.pageData.picture
        }
        addDeviceReport(data).then(res=>{
            if(res.data.code == 200){
                this.openNotification('success', res.data.msg)
                this.visible = false
                this.$emit('refreshTable',{})
            }else{
                this.openNotification('error', res.data.msg)
            }
        }).catch(err=>{
            this.openNotification('error', err.data.msg)
        })
    },
    openNotification: function(type, msg) {
        this.$notification[type]({
            message: "系统提示",
            description: msg,
        });
    },
  }
};
</script>

<style lang="less" scoped>
.modal_info{
    margin: 0 50px;
}
.repairRecord{
    margin: 0 50px;
}
.repairRecord_title{
    margin: 20px 0;
}
.modal_info_content{
    margin : 20px 20px 0
}
.picList{
    display: flex;
    align-items: center;
}
.picList img{
    width: 86px;
    height: 80px;
    margin-right: 20px;
}
.modal_info_title span{
    color: #d9d9d9;
}
.searchIpt{
    width: 100%;
}
</style>
